// src/redux/slices/receiptSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

const services = new Service();

export const uploadReceipt = createAsyncThunk(
  'receipt/uploadReceipt',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await services.post('schedule/upload-receipt', formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const receiptSlice = createSlice({
  name: 'receipt',
  initialState: {
    receiptData: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    resetReceiptState: (state) => {
      state.receiptData = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadReceipt.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadReceipt.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.receiptData = action.payload;
      })
      .addCase(uploadReceipt.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetReceiptState } = receiptSlice.actions;

export default receiptSlice.reducer;
