import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Define the initial state
const initialState = {
  sportData: [],
  loading: false,
  error: null,
};

// Define the asynchronous thunk to fetch data from the API
export const fetchSportData = createAsyncThunk("sports/fetchSportData", async (isActivated, thunkAPI) => {
  const services = new Service();
  try {
    const response = await services.get(`academy-sports/listview?is_activated=${isActivated}`);
    return response.data.content;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Create the sports slice
const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSportData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSportData.fulfilled, (state, action) => {
        state.loading = false;
        state.sportData = action.payload;
      })
      .addCase(fetchSportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default sportsSlice.reducer;
