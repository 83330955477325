import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const scheduleSportSlice = createSlice({
  name: 'scheduleSportState',
  initialState,
  reducers: {
    AddFilter: (state,action) => {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { AddFilter} = scheduleSportSlice.actions

export default scheduleSportSlice.reducer