import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Define the initial state
const initialState = {
  isProfileComplete: false,
  loading: false,
  error: null,
};

const services = new Service(); // Move this line outside of the async thunk

export const checkProfileCompleteness = createAsyncThunk(
  "profile/checkProfileCompleteness",
  async ({ userRole, query, id }) => {
    let url =
      userRole === 'academy'
        ? `academy/check-profile-completeness?userType=${query}&userId=${id}`
        : `${userRole}/check-profile-completeness`;

    try {
      const response = await services.get(url);
      return response.data;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
);

// Create a slice
const profileCompleteSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle checkProfileCompleteness pending and fulfilled actions
    builder.addCase(checkProfileCompleteness.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(checkProfileCompleteness.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.isProfileComplete = action.payload;
    });
    builder.addCase(checkProfileCompleteness.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default profileCompleteSlice.reducer;
