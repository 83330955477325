import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../../http"; // Update this import path to the correct location of your http.js file
import doAPI from "../../api";

const initialState = {
  centers: [],
  centersAndSports: [],
  totalPageCount: 0,
  loading: false,
  error: null,
};

export const fetchCenters = createAsyncThunk("center/fetchCenters", async ({ page_no, limit=25 }, thunkAPI) => {
  try {
    const service = new Service();
    const response = await service.get(`academy-center/academy?page_no=${page_no}&limit=${limit}`); // Adjust the API endpoint as needed

    // Check the response data in the console

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchCentersAndSports = createAsyncThunk("center/fetchCentersAndSports", async (reqData, thunkAPI) => {
  try {
    const result = await doAPI.getData("academy-center/center-sports");
    const response = result.data;
    if (response.status) {
      return response;
    } else {
      console.error(response.message[0].message);
      return thunkAPI.rejectWithValue({ error: response.message[0].message });
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const centerSlice = createSlice({
  name: "center",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCenters.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCenters.fulfilled, (state, action) => {
        state.loading = false;
        state.centers = action.payload?.content;
        state.totalPageCount = action.payload?.totalPages;
      })
      .addCase(fetchCenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCentersAndSports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCentersAndSports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.centersAndSports = payload?.data;
      })
      .addCase(fetchCentersAndSports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default centerSlice.reducer;
