// usersPlayersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../../http"; // Update this import path to the correct location of your http.js file
import doAPI from "../../api";

const initialState = {
  player: [],
  loading: false,
  error: null,
  totalPages: 0, // Initialize total pages count
  totalCount: 0, // Initialize total count of all entries
};

export const fetchPlayers = createAsyncThunk("users/fetchPlayers", async ({ centerId, sportId, program_id, page_no, limit = 25 }, thunkAPI) => {
  try {
    const result = await doAPI.getData(
      `player/listview?center_id=${centerId}&sport_id=${sportId}&program_id=${program_id}&page_no=${page_no}&limit=${limit}`
    );
    const response = result.data;
    if (response.status) {
      const { content, totalPages, totalCount } = response.data; // Extract total pages and total count

      return { content, totalPages, totalCount }; // Include total pages and total count in the payload
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changePlayerStatus = createAsyncThunk("users/changePlayerStatus", async ({ id, isChecked }, thunkAPI) => {
  try {
    const service = new Service();
    await service.put("player/change-status", {
      player_id: id,
      is_activated: isChecked ? 1 : 0,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const usersPlayersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPlayers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.loading = false;
        state.player = action.payload.content;
        state.totalPages = action.payload.totalPages; // Update total pages
        state.totalCount = action.payload.totalCount; // Update total count
      })
      .addCase(fetchPlayers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changePlayerStatus.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePlayerStatus.fulfilled, state => {
        state.loading = false;
      })
      .addCase(changePlayerStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default usersPlayersSlice.reducer;
