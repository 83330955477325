import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Button = ({
  children,
  type = "button",
  onClick,
  // href,
  to,
  className,
  size = "md",
  variant = "primary",
  isDisabled = false,
  disabled = false,
  rounded = "xs", // Set a default value for rounded
  ...props
}) => {
  // Define size classes
  const sizeClasses = {
    xs: "px-2 py-1 text-xs h-[25px]",
    sm: "px-2 py-1 text-sm h-[30px]",
    md: "px-4 py-0 text-md h-[35px]",
    lg: "px-6 py-3 text-lg h-[40px]",
  };

  // Define variant classes
  const variantClasses = {
    primary: "btn-admin-primary",
    secondary: "btn-admin-secondary",
    danger: "rounded-lg bg-transparent border-danger text-danger hover:bg-danger hover:text-white border",
  };

  // Define rounded classes
  const roundedClasses = {
    xs: "rounded-[5px]",
    sm: "rounded-[10px]",
    md: "rounded-[15px]",
    lg: "rounded-[20px]",
  };

  // Handle the ripple effect
  const handleRipple = e => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const ripple = document.createElement("span");
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.className = "ripple-effect";

    button.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  // Combine all classes
  const buttonClasses = classNames(
    "ripple focus:outline-none focus:ring h-[35px]",
    sizeClasses[size],
    variantClasses[variant],
    roundedClasses[rounded], // Ensure the correct class is added
    {
      "opacity-50 cursor-not-allowed": isDisabled,
    },
    {
      "opacity-50 cursor-not-allowed": disabled,
    },
    className
  );

  return (
    <>
      {to ? (
        <Link to={to} className={`${buttonClasses} bg-sla inline-flex items-center justify-center`} disabled={isDisabled || disabled} {...props}>
          {children}
        </Link>
      ) : (
        <button
          type={type}
          onClick={e => {
            handleRipple(e);
            if (onClick) onClick(e);
          }}
          className={buttonClasses}
          disabled={isDisabled || disabled}
          {...props}>
          {children}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]), // Added "xs" to the size prop types
  variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.oneOf(["xs", "sm", "md", "lg"]), // Added the rounded prop type
};

export default Button;
