import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';
const services = new Service();

// Define the initial state
const initialState = {
  loading: false,
  error: null,
};

// Define async thunk to handle logout API
export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  const userType = localStorage.getItem('usertype').replace(/"/g, '')
  try {
    const response = await services.put(userType+'/logout');
    return response.data;
  } catch (error) {
    // Handle error
    console.log(error)
    throw error;
  }
});

// Create a slice
const logoutSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle logoutUser pending and fulfilled actions
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      // You might want to reset additional state here if necessary
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default logoutSlice.reducer;
