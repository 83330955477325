import React, { useState } from "react";
import LtModal from "./LtModal";

export default function Image({ src, className, height, width, style, imgPreview }) {
  const [showImage, setShowImage] = useState(false);

  const handleCloseImage = () => setShowImage(false);
  const handleShowImage = () => setShowImage(true);
  return (
    <>
      <img
        onClick={event => handleShowImage(event)}
        className={className}
        src={src !== undefined ? src : ""}
        height={height}
        width={width}
        alt={src}
        style={style}
        // type={`images/${ext[0]}`}
      />

      {imgPreview && (
        <LtModal isOpen={showImage} onClose={handleCloseImage} heading="Image Preview" width={500}>
          <img src={src !== undefined ? src : ""} className="w-100" alt={src && src.replace(/^.*[\\\/]/, "")} />
        </LtModal>
      )}
    </>
  );
}
