import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CiWarning } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearState } from "../redux/loginSlice";
import { logoutUser } from "../redux/logoutSlice";
import NotificationComponent from "./NotificationComponent";

const LogoutModal = ({ showModal, setShowModal }) => {
  const frontend = useSelector(state => state?.frontend?.customizationData);
  const [countdownSeconds, setCountdownSeconds] = useState(180); // 3 minutes countdown
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login);
  const navigate = useNavigate()
  console.log(countdownSeconds)
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdownSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    if (countdownSeconds === 0) {
      localStorage.removeItem("countDown");
      dispatch(logoutUser());
      if (loginState.user_type === "player") {
        navigate("/player-login");
      } else if (loginState.user_type === "coach") {
        navigate("/coach-login");
      } else if (loginState.user_type === "academy") {
        navigate("/academy-login");
      }
      window.localStorage.clear();
      dispatch(clearState());
  
      // Automatically close modal after countdown finishes
      setShowModal(false);
    }
    console.log(countdownSeconds)
  }, [countdownSeconds, dispatch]);
  

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <NotificationComponent body={`You will be logged out in 3 Minutes.`} icon={frontend.logo} title="Important Alert"/>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center pb-5">
          <CiWarning style={{ fontSize: 60 }} />
          You will be logged out in {Math.ceil(countdownSeconds / 60*60)}{" "}
          {Math.ceil(countdownSeconds / 60*60) === 1 ? "second" : "seconds"}.
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
