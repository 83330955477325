import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import loginSlice from "./loginSlice";
import themeColorSlice from "./themeColorSlice";
import scheduleSportSlice from "./scheduleSportSlice";
import frontendSlice from "./frontendSlice";
import getPathSlice from "./getPathSlice";
import centerReducer from "./apiReduxSlice/centerSlice";
import ticketReducer from "./ticketSlice";
import centerSportReducer from "./apiReduxSlice/centerSportSlice";
import usersPlayersReducer from "./apiReduxSlice/usersPlayersSlice";
import usersCoachesReducer from "./apiReduxSlice/usersCoachesSlice";
import tournamentListReducer from "./apiReduxSlice/tournamentListSlice";
import bankAccountListReducer from "./apiReduxSlice/bankAccountList";
import usersMembersReducer from "./apiReduxSlice/usersMembersSlice";
import tournamentEntryReducer from "./apiReduxSlice/tournamentEntryListSlice";
import tournamentGradePointerReducer from "./apiReduxSlice/tennisTournamentGradePointerSlice";
import locationReducer from "./locationSlice";
import logoutReducer from "./logoutSlice";
import isProfileCompleteReducer from "./profileCompleteSlice";
import sportsReducer from "./sportsSlice";
import reportsReducer from "./reportsSlice";
import transactionsReducer from "./transactionsSlice";
import ProfileInfoReducer from "./ProfileInfoSlice";
import transactionsUserReducer from "./transactionsUserSlice";
import StationReducer from "./StationSlice";
import socialMediaReducer from "./socialMediaSlice";
import receiptReducer from "./receiptSlice";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  ticket: ticketReducer,
  login: loginSlice,
  themeColor: themeColorSlice,
  scheduleSport: scheduleSportSlice,
  frontend: frontendSlice,
  addressBar: getPathSlice,
  center: centerReducer,
  centerSports: centerSportReducer,
  players: usersPlayersReducer,
  coaches: usersCoachesReducer,
  members: usersMembersReducer,
  tournaments: tournamentListReducer,
  tournamentEntries: tournamentEntryReducer,
  tournamentGradePoint: tournamentGradePointerReducer,
  bankAccounts: bankAccountListReducer,
  locations: locationReducer,
  logout: logoutReducer,
  isProfileComplete: isProfileCompleteReducer,
  sports: sportsReducer,
  reports: reportsReducer,
  transactions: transactionsReducer,
  profileInfo: ProfileInfoReducer,
  transactionsUser: transactionsUserReducer,
  stationList: StationReducer,
  socialMedia: socialMediaReducer,
  receipt: receiptReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
