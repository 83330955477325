// 1. Set up Redux Store (assuming you have already set it up)

// 2. Create Redux Slice
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

// 3. Define Initial State
const initialState = {
  loading: false,
  data: null,
  error: null,
};
const services = new Service();

// 4. Write Async Thunk Action Creator
export const fetchPlayerDetails = createAsyncThunk(
  'player/fetchDetails',
  async () => {
    try {
      const response = await services.get('player/details');
      return response.data; // Assuming the API returns data directly
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
);

const playerDetailsSlice = createSlice({
  name: 'playerDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlayerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPlayerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export action creators and reducer
export const {} = playerDetailsSlice.actions;
export default playerDetailsSlice.reducer;
