// ScrollContext.js
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

export const ScrollContext = React.createContext(null);

export const ScrollProvider = ({ children }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  return (
    <ScrollContext.Provider value={{ scrollToTop, scrollToBottom }}>
      {children}
    </ScrollContext.Provider>
  );
};
