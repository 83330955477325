import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Create an instance of your service
const services = new Service();

// Define an async thunk for fetching transactions from the API
export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async ({ role_id, user_id, page_no, from_date, to_date }) => {
    // Accept parameters role_id and user_id
    const response = await services.get(
      `wallet/receipt-list?role_id=${role_id}&user_id=${user_id}&page_no=${page_no}&from_date=${from_date}&to_date=${to_date}`
    );
    console.log(response.data);
    return response.data;
  }
);

// Define the initial state
const initialState = {
  transactions: [],
  status: "idle",
  error: null,
};

// Create the transactions slice
const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    // Additional reducers can be defined here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state while fetching transactions
      .addCase(fetchTransactions.pending, (state) => {
        state.status = "loading";
      })
      // Handle the fulfilled state after successfully fetching transactions
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transactions = action.payload;
      })
      // Handle the rejected state if fetching transactions fails
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default transactionsSlice.reducer;

// Export any additional actions if needed
// export const { } = transactionsSlice.actions;
