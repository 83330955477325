import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

// Create an instance of your service
const services = new Service();

// Define an async thunk for fetching user wallet list from the API
export const fetchUserWalletList = createAsyncThunk(
  'userWalletList/fetchUserWalletList',
  async (page_no) => {
    const response = await services.get(`wallet/user-wallet-list?page_no=${page_no}`);
    console.log(response.data);
    return response.data;
  }
);

// Define the initial state
const initialState = {
  userWalletList: [],
  status: 'idle',
  error: null,
};

// Create the user wallet list slice
const userWalletListSlice = createSlice({
  name: 'userWalletList',
  initialState,
  reducers: {
    // Additional reducers can be defined here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state while fetching user wallet list
      .addCase(fetchUserWalletList.pending, (state) => {
        state.status = 'loading';
      })
      // Handle the fulfilled state after successfully fetching user wallet list
      .addCase(fetchUserWalletList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userWalletList = action.payload;
      })
      // Handle the rejected state if fetching user wallet list fails
      .addCase(fetchUserWalletList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userWalletListSlice.reducer;

// Export any additional actions if needed
// export const { } = userWalletListSlice.actions;
