import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Create an instance of your service
const services = new Service();

// Define an async thunk for fetching social media data from the API
export const fetchSocialMedia = createAsyncThunk(
  "socialMedia/fetchSocialMedia",
  async ({ is_activated }) => {
    const response = await services.get(`academy-social-media/listview?is_activated=${is_activated}`);
    return response.data.content; // Assuming the data you need is in the `content` field
  }
);

// Define the initial state
const initialState = {
  socialMedia: [],
  status: "idle",
  error: null,
};

// Create the social media slice
const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {
    // Additional reducers can be defined here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state while fetching social media data
      .addCase(fetchSocialMedia.pending, (state) => {
        state.status = "loading";
      })
      // Handle the fulfilled state after successfully fetching social media data
      .addCase(fetchSocialMedia.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.socialMedia = action.payload;
      })
      // Handle the rejected state if fetching social media data fails
      .addCase(fetchSocialMedia.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default socialMediaSlice.reducer;

// Export any additional actions if needed
// export const { } = socialMediaSlice.actions;
