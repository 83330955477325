// tournamentEntrySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../../http'; // Update this import path to the correct location of your http.js file

const initialState = {
   entries: [],
   withdraws: [],
   selectedEntry: null,
   loading: false,
   error: null,
};

export const fetchTournamentEntries = createAsyncThunk(
   'tournamentEntry/fetchTournamentEntries',
   async (_, thunkAPI) => {
      try {
         const service = new Service();
         const response = await service.get('tennis-tournament-entry/all-entry');
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(handleError(error));
      }
   }
);

export const fetchTournamentWithdraws = createAsyncThunk(
   'tournamentEntry/fetchTournamentWithdraws',
   async (_, thunkAPI) => {
      try {
         const service = new Service();
         const response = await service.get('tennis-tournament-entry/all-withdraw');
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(handleError(error));
      }
   }
);

export const fetchEntryDetail = createAsyncThunk(
   'tournamentEntry/fetchEntryDetail',
   async (entryId, thunkAPI) => {
      try {
         const service = new Service();
         const response = await service.get(`tennis-tournament-entry/detail/${entryId}`);
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(handleError(error));
      }
   }
);

const handleError = (error) => {
   if (error.response && error.response.data) {
      return error.response.data;
   } else {
      return { message: 'An error occurred' };
   }
};

const tournamentEntrySlice = createSlice({
   name: 'tournamentEntries',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchTournamentEntries.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchTournamentEntries.fulfilled, (state, action) => {
            state.loading = false;
            state.entries = action.payload;
         })
         .addCase(fetchTournamentEntries.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         .addCase(fetchTournamentWithdraws.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchTournamentWithdraws.fulfilled, (state, action) => {
            state.loading = false;
            state.withdraws = action.payload;
         })
         .addCase(fetchTournamentWithdraws.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         .addCase(fetchEntryDetail.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchEntryDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.selectedEntry = action.payload;
         })
         .addCase(fetchEntryDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default tournamentEntrySlice.reducer;
