import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const setting = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Flip,
};
export function notify(message, variant) {
  variant === "success" && toast.success(message, setting);
  variant === "warning" && toast.warn(message, setting);
  variant === "info" && toast.info(message, setting);
  variant === "error" && toast.error(message, setting);
  !variant && toast(message, setting);
}

export default Notification;
