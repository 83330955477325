import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

export default function CustomDropdown({ size, html, title, options, linkAs, onClick, className, dropDownIcon }) {
  return (
    <>
      <div className={`dropdown ${className}`}>
        <Link to={"#"} className="dropbtn flex items-center gap-2">
          {title} {dropDownIcon && <MdOutlineKeyboardArrowDown />}
        </Link>
        <div className="dropdown-content">
          {html}
          {options &&
            options.map((i, index) => (
              <React.Fragment key={index}>
                {linkAs !== "a" ? (
                  i.onClick ? (
                    <span onClick={i.onClick}>{i.label}</span>
                  ) : (
                    <Link to={i.link}>{i.label}</Link>
                  )
                ) : i.onClick ? (
                  <span onClick={i.onClick}>{i.label}</span>
                ) : (
                  <a href={i.link}>{i.label}</a>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );
}
