// bankAccountList.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../../http'; // Update this import path to the correct location of your http.js file

const initialState = {
   banklist: [],
   totalPageCount: 0,
   loading: false,
   error: null,
};

export const fetchBankList = createAsyncThunk(
   'bank/fetchBankList', async (thunkAPI) => {
      try {
         const service = new Service();
         const queryString = `academy-bank/listview`;
         const response = await service.get(queryString);
         
         return response.data;
      } catch (error) {
         // If error.response exists and contains data, use it as the payload
         if (error.response && error.response.data) {
            return thunkAPI.rejectWithValue(error.response.data);
         } else {
            // If no structured error data is available, return a default error payload
            return thunkAPI.rejectWithValue({ message: 'An error occurred' });
         }
      }
   }
);

const bankAccountList = createSlice({
   name: 'banklist',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchBankList.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchBankList.fulfilled, (state, action) => {
            state.loading = false;
            state.banklist = action.payload.content;
            state.totalPageCount = action.payload.totalPages;
         })
         .addCase(fetchBankList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default bankAccountList.reducer;
