// customizeFrontendSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

const initialState = {
  customizationData: {},
  loading: false,
  error: null,
};

export const fetchCustomizationData = createAsyncThunk("customizeFrontend/fetchCustomizationData", async (_, thunkAPI) => {
  try {
    const result = await doAPI.getData("customize-frontend");
    const response = result.data;
    if (response.status) {
      return response.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const customizeFrontendSlice = createSlice({
  name: "customizeFrontend",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCustomizationData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomizationData.fulfilled, (state, action) => {
        state.loading = false;
        state.customizationData = action.payload;
      })
      .addCase(fetchCustomizationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default customizeFrontendSlice.reducer;
